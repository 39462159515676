import { useEffect } from "react";
import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import axios from "axios";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import configs, { getParameterByName } from "../Constants";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Setting = () => {
  const [merchantCode, setMerchantCode] = useState("");
  const [providerId, setProviderId] = useState("");
  const [paymentGateWay, setPaymentGateWay] = useState("");
  const [bgImage, setBgImage] = useState("");
  const [delivaryPartner, setDelivaryPartner] = useState("");
  const [currency, setCurrency] = useState("");
  const [logo, setLogo] = useState("");

  const [dialogOPen, setDialogOPen] = useState(false);
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState([]);

  const [provider, setProvider] = useState("");
  const [providerTitle, setProviderTitle] = useState("");
  const [providerCode, setProviderCode] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [clovId, setClovId] = useState("");
  const [isTest, setIsTest] = useState("");
  const [region, setRegion] = useState("");
  const [meal, setMeal] = useState(false);
  const [providerInfoId, setProviderInfoId] = useState("");
  const [onlyTakeAway, setOnlyTakeAway] = useState(false);
  const [MenuLeft, setMenuLeft] = useState(false);

  const [providerDetail, setProviderDetail] = useState([]);

  const [value, setValue] = useState("1");

  let baseURL = configs.baseURL;

  const handleChange = (event: React.SyntheticEvent, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };

  let userData = sessionStorage.getItem("userData")
    ? JSON.parse(sessionStorage.getItem("userData"))
    : "";
  console.log(userData);
  const userId = userData ? userData.sub : " ";

  const handleSubmit = () => {
    console.log("hello");
    if (providerInfoId) {
      if (merchantCode) {
        axios
          .put(baseURL + "/api/settings/" + providerInfoId, {
            userId: userId,
            merchantCode: merchantCode,
            activeProviderId: providerId,
            sokBGImg: bgImage,
            activePaymentGateway: paymentGateWay,
            activeDeliveryPartner: delivaryPartner,
            currency: currency,
            logoImg: logo,
            id: providerInfoId,
            onlyTakeAway: onlyTakeAway ? onlyTakeAway : false,
            isLeftAlign: MenuLeft,
            filterVegNonVeg: meal ? meal : false,
          })
          .then((res) => {
            console.log(res);
            axios.get(baseURL + "/api/settings/" + userId).then((res) => {
              console.log(res.data);
              setUserInfo(res.data);
            });
            setProviderInfoId("");
            setOpen(false);
            setMerchantCode("");
            setProviderId("");
            setPaymentGateWay("");
            setBgImage("");
            setDelivaryPartner("");
            setCurrency("");
            setLogo("");
            setOnlyTakeAway("");
            setMenuLeft();
            setMeal("");
          });
      } else {
        console.log("err");
      }
    } else {
      if (merchantCode) {
        axios
          .post(
            baseURL + "/api/settings",
            // "http://15.204.58.171:6006/api/settings",

            {
              userId: userId,
              merchantCode: merchantCode, // like USPIZZA-KEMP
              activeProviderId: providerId,
              sokBGImg: bgImage,
              activePaymentGateway: paymentGateWay,
              activeDeliveryPartner: delivaryPartner,
              currency: currency,
              logoImg: logo,
              onlyTakeAway: onlyTakeAway,
              isLeftAlign: MenuLeft,
              filterVegNonVeg: meal,
            }
          )
          .then((res) => {
            console.log(res);

            axios.get(baseURL + "/api/settings/" + userId).then((res) => {
              console.log(res.data);
              setUserInfo(res.data);
            });

            setOpen(false);
            setMerchantCode("");
            setProviderId("");
            setPaymentGateWay("");
            setBgImage("");
            setDelivaryPartner("");
            setCurrency("");
            setLogo("");
            setOnlyTakeAway("");
            setMenuLeft();
            setMeal("");
          });
      } else {
        console.log("err");
      }
    }
  };

  useEffect(() => {
    axios.get(baseURL + "/api/settings/" + userId).then((res) => {
      console.log(res.data);
      setUserInfo(res.data);
    });

    axios.get(baseURL + "/api/thp-source?userId=" + userId).then((res) => {
      console.log(res.data);
      setProviderDetail(res.data);
    });
  }, []);

  const handleDialog = () => {
    setOpen(true);
    let randomCode = randomString(10, "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ");
    setMerchantCode(randomCode);
  };

  function randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  // /thp-source

  const handleClover = () => {
    let merchantDetails = {
      providerTitle: providerTitle,
      providerCode: providerCode,
      apiKey: apiKey,
      isTestAccount: isTest,
      region: region,
    };

    if (clovId) {
      let data = document.getElementById("country").value;
      console.log(data);

      axios
        .put(baseURL + "/api/thp-source/" + clovId, {
          userId: userId,
          provider: provider,
          merchantDetails: JSON.stringify(merchantDetails),
        })
        .then((res) => {
          console.log(res);
          setApiKey("");

          setProviderCode("");
          setProviderTitle("");
          setProvider("");
          setClovId("");
          setIsTest("");
          setRegion("");

          axios
            .get(baseURL + "/api/thp-source?userId=" + userId)
            .then((res) => {
              console.log(res.data);
              setProviderDetail(res.data);
              console.log("hello");
              setOpen(false);
            });
        });
    } else if (providerCode && providerTitle) {
      axios
        .post(baseURL + "/api/thp-source", {
          userId: userId,
          provider: provider,
          merchantDetails: JSON.stringify(merchantDetails),
        })
        .then((res) => {
          console.log(res);
          setApiKey("");
          setProviderCode("");
          setProviderTitle("");
          setProvider("");
          setIsTest("");
          setRegion("");

          axios
            .get(baseURL + "/api/thp-source?userId=" + userId)
            .then((res) => {
              console.log(res.data);
              setProviderDetail(res.data);
              setOpen(false);
            });
        });
    } else {
      console.log("errr");
    }
  };

  // const handleDelete = (cloverId) => {
  //   axios.delete(baseURL + "/api/thp-source/" + cloverId).then((res) => {
  //     console.log(res);
  //     axios.get(baseURL + "/api/thp-source?userId=" + userId).then((res) => {
  //       console.log(res.data);
  //       setProviderDetail(res.data);
  //     });
  //   });
  // };
  const handleEdit = (cloverId) => {
    console.log(cloverId);
    let filterData = providerDetail.filter((pro) => pro.id === cloverId);
    console.log(filterData);
    setClovId(cloverId);
    setDialogOPen(true);

    let data = JSON.parse(filterData[0].merchantDetails);
    console.log(data);

    setApiKey(data.apiKey);
    setProviderCode(data.providerCode);
    setProviderTitle(data.providerTitle);
    setProvider(filterData[0].provider);
    setIsTest(data.isTestAccount);
    setRegion(data.region);
  };

  function handleProviderEdit(infoId) {
    console.log(infoId);
    setProviderInfoId(infoId);
    let fltData = userInfo.filter((info) => info.id === infoId);
    console.log(fltData);
    setMerchantCode(fltData[0].merchantCode);
    setProviderId(fltData[0].activeProviderId);
    setPaymentGateWay(fltData[0].activePaymentGateway);
    setBgImage(fltData[0].sokBGImg);
    setDelivaryPartner(fltData[0].activeDeliveryPartner);
    setCurrency(fltData[0].currency);
    setLogo(fltData[0].logoImg);
    setOnlyTakeAway(fltData[0].onlyTakeAway);
    setMenuLeft(fltData[0].isLeftAlign);
    setMeal(fltData[0].filterVegNonVeg);
    setOpen(true);
  }

  function handleProviderDelete(infoId) {
    console.log(infoId);
    axios.delete(baseURL + "/api/settings/" + infoId).then((res) => {
      console.log(res.data);
      axios.get(baseURL + "/api/settings/" + userId).then((res) => {
        console.log(res.data);
        setUserInfo(res.data);
      });
    });
  }

  // if(userInfo.lenght === 0){
  //     let randomCode=randomString(10, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ');
  //     setMerchantCode(randomCode);
  // }

  return (
    <div className="container">
      <div className="header" style={{ marginBottom: "-7px" }}>
        <h4>Setting</h4>
        {!userInfo.length ? (
          <button className="add_btn" onClick={handleDialog}>
            {" "}
            <AddIcon /> ADD New
          </button>
        ) : (
          ""
        )}
      </div>

      <Dialog
        open={open || userInfo.lenght === 0}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
          Add Merchant Info
        </DialogTitle>
        <div style={{ padding: "20px" }}>
          <div className="row">
            <div className="col">
              <Box
                sx={{
                  width: 400,
                  maxWidth: "100%",
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  id="fullWidth"
                  label="Merchant Code"
                  value={merchantCode}
                  disabled
                  onChange={(e) => setMerchantCode(e.target.value)}
                />
              </Box>
            </div>
            <div className="col">
              <label>Activate Provider :</label>
              <select
                onChange={(e) => setProviderId(e.target.value)}
                className="select_input"
              >
                <option>Select</option>
                <option value="" selected={providerId === ""}>
                  CUSTOM
                </option>
                {providerDetail.map((pro, i) => {
                  console.log(pro);
                  let detail = JSON.parse(pro.merchantDetails);
                  return (
                    <option selected={pro.id === providerId} value={pro.id}>
                      {pro.provider}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {/* <input placeholder="Enter Provider ID " onChange={(e)=>setProviderId(e.target.value)} className="form-control w-100"   style={{height:"40px"}}/> */}

          <div className="row">
            <div className="col">
              <Box
                sx={{
                  width: 400,
                  maxWidth: "100%",
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  id="fullWidth"
                  label="Active Payment Gateway"
                  defaultValue={paymentGateWay}
                  onChange={(e) => setPaymentGateWay(e.target.value)}
                />
              </Box>
            </div>

            <div className="col" style={{ float: "left", width: "50%" }}>
              <Box
                sx={{
                  width: 400,
                  maxWidth: "100%",
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  id="fullWidth"
                  label="SOK Background Image"
                  defaultValue={bgImage}
                  onChange={(e) => setBgImage(e.target.value)}
                />
              </Box>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Box
                sx={{
                  width: 400,
                  maxWidth: "100%",
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  id="fullWidth"
                  label="Currency"
                  defaultValue={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                />
              </Box>
            </div>

            <div className="col">
              <Box
                sx={{
                  width: 400,
                  maxWidth: "100%",
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  id="fullWidth"
                  label="Enter Logo url"
                  defaultValue={logo}
                  onChange={(e) => setLogo(e.target.value)}
                />
              </Box>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Box
                sx={{
                  width: 400,
                  maxWidth: "100%",
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  id="fullWidth"
                  label="Delivary Partner"
                  defaultValue={delivaryPartner}
                  onChange={(e) => setDelivaryPartner(e.target.value)}
                />
              </Box>
            </div>
            <div className="col">
              <Box
                sx={{
                  width: 400,
                  maxWidth: "100%",
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  id="fullWidth"
                  type="number"
                  label="Online Price increase by %"

                  // onChange={(e) => setDelivaryPartner(e.target.value)}
                />
              </Box>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Take Away"
                  checked={onlyTakeAway}
                  onChange={(e) => setOnlyTakeAway(e.target.checked)}
                />
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Filter VegNonVeg"
                  onChange={(e) => setMeal(e.target.checked)}
                  checked={meal}
                />
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Set Menu Left"
                  onChange={(e) => setMenuLeft(e.target.checked)}
                  checked={MenuLeft}
                />
              </FormGroup>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              textAlign: "right",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpen(false)}
              style={{ marginRight: "20px" }}
            >
              Cancel
            </Button>

            <Button variant="contained" color="success" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </Dialog>

      <TabContext value={value} >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            textAlign: "center",
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Details" value="1" />
            <Tab label="Source" value="2" />
            {/* <Tab label="Active account" value="3" /> */}
          </TabList>
        </Box>

        {/* Table 1 starts from heree */}
        <TabPanel value="1">
          {userInfo.length
            ? userInfo.map((info, i) => {
                console.log(info);
                let fData = providerDetail.filter(
                  (cl) => cl.id === info.activeProviderId
                );
                let proName = fData.length ? fData[0].provider : "CUSTOM";

                return (
                  <div className="category-list" style={{height:'calc(100% - 200px)'}} key={i}>
                    <div className="rowS ">
                      <div className="col">
                        <span>Provider Name</span>
                        <br/><b><span className="pName">{proName}</span></b>
                      </div>
                      <div className="col-end">
                        <Button
                          variant="contained"
                          color="success"
                          style={{ padding: "10px" }}
                          className="editBtn"
                          onClick={() => handleProviderEdit(info.id)}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>

                    <div className="rowS">
                      <div className="col">
                        <p className="line1">Merchant Code</p>
                        <p className="line2"><b>{info.merchantCode}</b></p>
                      </div>
                      <div className="col">
                        <p className="line1">Active Payment Gateway</p>
                        <p className="line2">{info.activePaymentGateway}</p>
                      </div>
                    </div>

                    <div className="rowS">
                      <div className="col">
                        <p className="line1">Currency</p>
                        <p className="line2">{info.currency}</p>
                      </div>
                      <div className="col">
                        <p className="line1">Delivery Partner</p>
                        <p className="line2">{info.activeDeliveryPartner}</p>
                      </div>
                    </div>

                    <div className="rowS">
                      <div className="col">
                        <p className="line1">Only Take Away</p>
                        <p className="line2">
                          {info.onlyTakeAway ? "Yes" : "No"}
                        </p>
                      </div>
                      <div className="col">
                        <p className="line1"> Set Menu Left</p>
                        <p className="line2">
                          {info.isLeftAlign ? "Left" : "Right"}
                        </p>
                      </div>
                    </div>

                    <div className="rowS">
                      <div className="col">
                        <p className="line1">Veg/Non-Veg Filter</p>
                        <p className="line2">
                          {info.filterVegNonVeg ? "Yes" : "No"}
                        </p>
                      </div>
                      <div className="col">
                        <p className="line1">Logo</p>
                        <img className="line2" src={info.logoImg} width="125px" />
                      </div>
                    </div>

                    <div className="rowS">
                      <div className="col">
                        <p className="line1" >Background Image </p>
                        <img className="line2" src={info.sokBGImg} width="125px" height="50px" />
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </TabPanel>

        {/* Table 1 ends here  */}
        <TabPanel value="2">
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button className="add_btn" onClick={() => setDialogOPen(true)}>
              <AddIcon /> Add New
            </button>
          </div>
          <Dialog open={dialogOPen} maxWidth="lg" fullWidth={true}>
            <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
              {"Add Provider"}
            </DialogTitle>
            <div
              style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
              }}
              className={"rowinput"}
            >
              <span>
                <lable>Provider *</lable>
                <input
                  className="input_cls"
                  placeholder="Enter Provider"
                  onChange={(e) => setProvider(e.target.value)}
                  defaultValue={provider}
                />
              </span>
              <span>
                <lable>Provider Title *</lable>
                <input
                  className="input_cls"
                  placeholder="Enter Provider title"
                  defaultValue={providerTitle}
                  onChange={(e) => setProviderTitle(e.target.value)}
                />
              </span>
              <span>
                <lable>Provider Code *</lable>
                <input
                  className="input_cls"
                  placeholder="Enter Provider code"
                  defaultValue={providerCode}
                  onChange={(e) => setProviderCode(e.target.value)}
                />
              </span>
              <br />
              <span>
                <lable>Api Key *</lable>
                <input
                  className="input_cls"
                  placeholder="Enter api key"
                  defaultValue={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                />
              </span>
              <span>
                <label>Test Account :</label>
                <input
                  type="checkbox"
                  defaultValue={isTest}
                  onChange={(e) => setIsTest(e.target.checked)}
                />
              </span>

              <br />
              <span>
                <label for="country">Region :</label>

                <select
                  name="country"
                  id="country"
                  className="select_input"
                  defaultValue={region}
                  placeholder="Select Country"
                  onChange={(e) => setRegion(e.target.value)}
                >
                  <option value="" disabled selected hidden>
                    Select Country
                  </option>
                  <option value="usa">USA</option>
                  <option value="india">India</option>
                  <option value="uae">UAE</option>
                  <option value="australia">Australia</option>
                </select>
              </span>

              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  marginTop: "20px",
                  textAlign: "right",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  style={{ marginRight: "20px" }}
                  onClick={() => setDialogOPen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleClover}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Dialog>

          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "8px",
              marginTop: "10px",
            }}
          >
            <table
              align="center"
              cellPadding="5px"
              style={{ padding: "10px", width: "100%" }}
            >
              <thead style={{ background: "#f1f1f1" }}>
                <tr>
                  <th>#</th>
                  <th>Provider</th>
                  <th>Merchant Name</th>
                  <th>Merchant Code</th>
                  <th>Key</th>
                  <th>Test Account</th>
                  <th>Region</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {providerDetail.map((pro, i) => {
                  let detail = JSON.parse(pro.merchantDetails);
                  console.log(detail.isTestAccount);
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{pro.provider}</td>
                      <td>{detail.providerTitle}</td>
                      <td>{detail.providerCode}</td>
                      <td>{detail.apiKey}</td>
                      <td>{detail.isTestAccount ? "Yes" : "No"}</td>
                      <td>{detail.region}</td>
                      <td>
                        <IconButton
                          aria-label="delete"
                          color="info"
                          onClick={() => handleEdit(pro.id)}
                          className="btn bg-light mx-2"
                        >
                          <EditIcon />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
      </TabContext>
    </div>
  );
};

export default Setting;

// {\"serviceName\":\"Clover\",\"
// providerTitle\":\"THE CHEFS DELIGHT AT STO\",\
// "providerCode\":\"YF5XNRVSS39C1\",\
// "apiKey\":\"6fdaada1-8786-aa21-2b03-a166e0e4d938\"}
