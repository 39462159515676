import axios from "axios";
import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import configs, { getParameterByName } from "../Constants";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-responsive-list";
import { TextField } from "@mui/material";
import {Box} from "@mui/material";
import "react-responsive-list/assets/index.css";
import Gallery from "./Gallery";

function Category(props) {
  const [foodName, setFoodName] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const [filterCat, setFilterCat] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [catId, setCatId] = useState("");
  const [addonsGroup, setAddonsGroup] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addOn, setAddOn] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [image, setImage] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  let baseURL = configs.baseURL;
  let userData = sessionStorage.getItem("userData")
    ? JSON.parse(sessionStorage.getItem("userData"))
    : "";

  let merchantData = sessionStorage.getItem("merchantData")
    ? JSON.parse(sessionStorage.getItem("merchantData"))
    : null;

  const merchCode = merchantData ? merchantData.merchantCode : "";

  const getCatByUserUrl = `${baseURL}/api/categories?merchantCode=${merchCode}`;

  useEffect(() => {
    fetchCatData();
  }, []);
  const handleSelectedImage = (image) => {
    setSelectedImage(image);
  };
  const handleFoodNameChange = (event) => {
    setFoodName(event.target.value);
  };

  const handleImageURLChange = (e) => {
    var formData = new FormData();
    let file = e.target.files[0];
    let fileName = "cat_" + new Date().getTime() + file.name;
    formData.append("uploader", file, fileName);
    let postQueriesUrl = baseURL + "/api/upload/INVENTORY_ITEM";
    axios.post(postQueriesUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    setImageURL("app-uploads/customers/inventories/" + fileName);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!foodName) {
      console.log("All feilds are mandatry");
    } else if (catId) {
      axios
        .put(
          baseURL + "/api/categories/" + catId + `?merchantCode=${merchCode}`,
          {
            name: foodName,
            image: imageURL ? imageURL : selectedImage.image,
            tags: tags.length ? tags.join("~") : "",
            isAddOn: addOn,
            userId: userData.sub,
          }
        )
        .then((response) => {
          fetchCatData();
        });
    } else {
      axios
        .post(`${baseURL}/api/categories?merchantCode=${merchCode}`, {
          name: foodName,
          image:imageURL ? imageURL : selectedImage.image,
          tags: tags.length ? tags.join("~") : "",
          isAddOn: addOn,
          userId: userData.sub,
        })
        .then((response) => {
          fetchCatData();
        });
    }
  };

  const handleDelete = (id) => {
    console.log(id);
    axios.delete(baseURL + "/api/categories/" + id).then((response) => {
      fetchCatData();
    });
  };

  const fetchCatData = () => {
    axios.get(getCatByUserUrl).then((response) => {
      console.log(response.data);
      setCategories(response.data);
      setDialogOpen(false);
      setAddOn(false);
      setTags("");
      setFoodName("");
      setImageURL("");
      setSelectedOption("");
    });
  };

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value]);
      //props.selectedTags([...tags, event.target.value]);
      event.target.value = "";
    }
  };
  const handleInputChange = (e) => {
    var formData = new FormData();
    let file = e.target.files[0];
    console.log(e.target);
    console.log(file);
    let fileName = "pro_" + new Date().getTime() + file.name;

    formData.append("uploader", file, fileName);
    let postQueriesUrl = baseURL + "/api/upload/INVENTORY_ITEM";
    axios.post(postQueriesUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    setImageURL("app-uploads/customers/inventories/" + fileName);

    //setImageURL(file);
  };
  const handleAddon = () => {
    setAddOn(!addOn);
  };

  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = "./images/blank.jpg";
  };

  const handleSearch = (e) => {
    let val = e.target.value;
    let fltData = categories.filter(
      (cat) => cat.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
    );
    console.log(fltData);
    setFilterCat(fltData);
    setIsSearch(val ? true : false);
  };
  const handleSearch1 = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEdit = (cat) => {
    console.log(cat);
    setAddOn(cat.isAddOn);
    setTags(cat.tags.split("~"));
    setFoodName(cat.name);
    setImageURL(cat.image);
    setCatId(cat.id);
    setDialogOpen(true);
  };
  const categorieItems = isSearch ? filterCat : categories;

  const handleGallery = () => {
    setShowGallery(true);
  };
  const handleUpload = () => {
    setImage(true);
  };
  const handleSubmitImage = () => {
    if (selectedImage) {
      console.log("Selected Image:", selectedImage);
    } else {
      console.error("No image selected!");
    }
    setShowGallery(false); 
  };
  return (
    <div style={{ height: "98vh" }}>
      <Dialog open={dialogOpen} maxWidth="mb" fullWidth={true}>
        <DialogTitle style={{ textAlign: "center", fontWeight: "bold" }}>
          {catId ? "Edit Category" : "Add Category"}
        </DialogTitle>

        <div
          className="container"
          style={{ padding: "20px 40px", borderRadius: "10px", margin: "20px" }}
        >
          <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", margin:"10px 0px"}}>
          <label>
            Category name <span className="text-danger">*</span>
          </label>
          <TextField
            type="text"
            defaultValue={foodName}
            fullWidth
            size="small"
            value={foodName}
            onChange={handleFoodNameChange}
          />

          </div>
        
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button variant="contained" onClick={handleGallery}>
              Add From Gallery
            </Button>
            <Button variant="contained" onClick={handleUpload}>
              Upload New
            </Button>
          </div>
          <span style={{ fontSize: "1rem" }}>
                        {selectedImage ? selectedImage.name : ""}
                      </span>
          <div
                      className="colchoose"
                      style={image ? { display: "block" } : { display: "none" }}
                    >
                      <Box
                        sx={{
                          maxWidth: "100%",
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          fullWidth
                          id="fullWidth"
                          label=""
                          defaultValue=""
                          onChange={handleInputChange}
                          type="file"
                          name="image"
                        />
                      </Box>
                    </div><br />
                    <div style={{marginTop:"10px"}}>
                      <Box>
                      <span
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            Add-on:
            <input
              type="checkbox"
              defaultChecked={addOn}
              style={{
                height: "25px",
                width: "25px",
                marginLeft: "5px",
                accentColor: "#3622cc",
              }}
              checked={addOn}
              onChange={handleAddon}
            />
          </span>
                      </Box>
                    </div>
       
          <label>Tags</label>
          <div className="tags-input">
            <ul id="tags">
              {tags.length ? (
                tags.length &&
                tags.map((tag, index) => (
                  <li key={index} className="tag">
                    <span className="tag-title">{tag}</span>
                    <span className="btn" onClick={() => removeTags(index)}>
                      x
                    </span>
                  </li>
                ))
              ) : (
                <span className="tag-title"></span>
              )}{" "}
            </ul>
            <input
              className="input_cls"
              type="text"
              onKeyUp={(event) =>
                event.key === "Enter" ? addTags(event) : null
              }
              placeholder="Press enter to add tags"
            />
          </div>
          <div className="fixed-buttons">
          <Button
            variant="contained"
            color="error"
            style={{ margin: "20px" }}
            onClick={() => {
              setDialogOpen(false);
              setAddOn(false);
              setTags("");
              setFoodName("");
              setImageURL("");
              setCatId("");
            }}
          >
            Close
          </Button>

          <Button
            variant="contained"
            color="success"
            style={{ margin: "20px" }}
            onClick={(e) => handleSubmit(e)}
          >
            Save
          </Button>
                </div>
        
        </div>
      </Dialog>
      <Dialog
          onClose={() => setShowGallery(false)}
          open={showGallery}
          fullWidth={true}
          style={{ width: "600px", margin: "auto" }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div
              className="header"
              style={{
                flex: "0 0 auto",
                padding: "8px",
                borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                backgroundColor: "#fff",
                zIndex: "1",
              }}
            >
              <div
                className="search"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search Images"
                  onChange={handleSearch1}
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    backgroundColor: "transparent",
                    marginLeft: "8px",
                  }}
                />
              </div>
            </div>

            <div
              className="content"
              style={{ flex: "1 1 auto", overflowY: "auto", padding: "8px" }}
            >
              <Gallery
                onSelectImage={handleSelectedImage}
                searchQuery={searchQuery}
              />
            </div>

            <div
              className="footer"
              style={{
                flex: "0 0 auto",
                padding: "8px",
                borderTop: "1px solid #ccc",
                position: "sticky",
                bottom: "0",
                backgroundColor: "#fff",
                zIndex: "1",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button variant="contained" onClick={() => setShowGallery(false)}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmitImage}>
                Submit
              </Button>
            </div>
          </div>
        </Dialog>
      <div className="header">
        <h4>Categories</h4>
        <div className="search">
          <SearchIcon />
          <input
            type="text"
            className="search_input"
            placeholder="Search"
            onChange={handleSearch}
          />
        </div>
        {merchCode && !merchCode.activeProviderId ? (
          <button className="add_btn" onClick={() => setDialogOpen(true)}>
            <AddIcon /> Add New
          </button>
        ) : (
          <span></span>
        )}
      </div>
      <div className="category-list" style={{ padding: "20px" }}>
        <Table style={{ width: "100%" }}>
          <Thead>
            <Tr>
              <Th style={{ width: "25%" }}>Categories</Th>
              <Th style={{ width: "25%" }}>Images</Th>
              <Th></Th>
              <Th style={{ width: "25%" }}>Tags</Th>
              <Th>
                {" "}
                {userInfo.length && userInfo[0].activeProviderId === "" ? (
                  <span>Action</span>
                ) : (
                  ""
                )}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {categorieItems && categorieItems.length
              ? categorieItems.map((category, k) => (
                  <Tr
                    key={category.id}
                    style={{ borderBottom: "1px solid #f0eeee" }}
                  >
                    <Td style={{ fontWeight: "bold" }} align="start">
                      {category.name}
                    </Td>
                    <Td>
                      <img
                        alt="cat"
                        src={
                          category.image === ""
                            ? "./images/blank.jpeg"
                            : baseURL + "/" + category.image
                        }
                        onError={imageOnErrorHandler}
                        style={{
                          width: "100px",
                          height: "60px",
                          borderRadius: "5px",
                        }}
                      />
                    </Td>
                    <Td style={{ fontSize: "13px" }}>
                      {category.isAddOn ? "ADD-ON" : ""}
                    </Td>
                    <Td style={{ width: "25%" }}>{category.tags}</Td>
                    {!merchCode.activeProviderId ? (
                      <Td>
                        <IconButton
                          aria-label="edit"
                          size="large"
                          color="info"
                          onClick={() => handleEdit(category)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          size="large"
                          color="error"
                          onClick={() => handleDelete(category.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Td>
                    ) : (
                      ""
                    )}
                  </Tr>
                ))
              : ""}
          </Tbody>
        </Table>
      </div>
    </div>
  );
}

export default Category;
