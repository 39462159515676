import React, { useEffect, useState,useRef } from "react";
import moment from "moment";
import configs, { getParameterByName } from "../Constants";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Currencies from "./Currencies";
import html2canvas from "html2canvas";
function BillPrint(props) {

console.log(props)
  const [loading, setLoading] = useState(true);
  const [print, setPrint] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);
  const orderId = props.orderDetails ? props.orderDetails.orderId :getParameterByName("orderId");
  const currency =  props.orderDetails ? props.orderDetails.currency :getParameterByName("currency");
  const restaurant = props.orderDetails ? props.orderDetails.restaurant : getParameterByName("restaurant");
  const address = props.orderDetails ? props.orderDetails.address : getParameterByName("address");
  const cgst = getParameterByName("cgst");
  const nssi = getParameterByName("nssi");
  const invoiceNo = props.orderDetails ? props.orderDetails.invoice_no : getParameterByName("invoice_no");
  const merchantCode = props.orderDetails ? props.orderDetails.merchantCode : getParameterByName("merchantCode");
  const baseURL = configs.baseURL;
  // const [canvasUrl, setCanvasUrl] = useState(null);
  const billRef = useRef(null);
  useEffect(() => {
    if(orderId){
      const getBill = `${baseURL}/api/orders/${orderId}?merchantCode=${merchantCode}`;
      axios.get(getBill).then((response) => {
        setOrderDetails(response.data);
        setLoading(false);
      });
    }

  }, [orderId, merchantCode, baseURL]);

  useEffect(() => {
    
 let isPrinted = localStorage.getItem('isPrintCall')
    if (props.orderDetails && isPrinted === "N" && !loading ) {

      localStorage.setItem('isPrintCall', "Y");
      let bill= document.getElementById("bill");
      console.log(bill);
      if (bill) {
        html2canvas(bill).then((canvas) => {
          const canvasUrl = canvas.toDataURL("image/png");
          const base64Image = canvasUrl.split(",")[1];
          console.log(base64Image);
          window.PrintInterface.print(base64Image);
          localStorage.setItem('isPrintCall', "N");
          props.setBillPrint(false);
        }).catch(error => {
          console.error("Error capturing the bill:", error);
        });
 
      }
    }
}, [loading])

  


function handlePrint() {
  setPrint(false);

    const printButtonContainer = document.getElementById("print-container");
    if (printButtonContainer) {
      printButtonContainer.style.display = "none";
    }

    // if (window.PrintInterface && typeof window.PrintInterface.print === 'function') {
    if (window.PrintInterface) {
      window.PrintInterface.print();
      console.log("mobile");
    } else {
      console.log("No print support");
      window.print();
    }
  }

  function curSymbol() {
    let cur = Currencies.filter(
      (curen) => curen.abbreviation == currency.toUpperCase()
    );
    console.log(cur);
    return cur.length && cur[0].symbol;
  }

  const handleBack = () => {
    let isMerchant = sessionStorage.getItem("isMerchant");
    sessionStorage.setItem("billing", false);
    console.log(isMerchant);
    if (isMerchant) {
      window.location.href = `/epos?merchantCode=${merchantCode}`;
    } else {
      window.location.href = "/epos";
    }
  };


  const renderCurrencySymbol = () => {
    return <span dangerouslySetInnerHTML={{ __html: curSymbol() }} />;
  };
  const [addressPart, gstPart, fssaiPart] = address.split(/GST:|FSSAI:/);
  console.log(addressPart, gstPart, fssaiPart);
  const bodystyle = props.orderDetails ? { position: "absolute", zIndex: "-1" } : {};
console.log(orderDetails)
  return (
    <div style={bodystyle}>

       <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowBackIcon
              onClick={handleBack}
              style={{ marginLeft: "10px" }}
            />
            <div
              id="print-container"
              style={
                print
                  ? {
                      display: "block",
                      textAlign: "left",
                      marginLeft: "180px",
                      marginTop: "10px",
                    }
                  : { display: "none" }
              }
            >
              <button id="print" onClick={handlePrint}>
                Print
              </button>
            </div>
          </div>
       
        <div id="bill" >
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <img
                id="load"
                style={{ width: "400px", height: "400px", marginLeft: "10px" }}
                src="./billsummary/loading-icon.gif"
              />
            </div>
          ) : (
            <div
              className="container"
              style={{
                textAlign: "left",
                justifyContent: "start",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div id="orderDetails" style={{ textAlign: "left" }}>
                <span style={{display:"inline",textAlign:'left',fontSize:'14px'}}>Token:</span><h5 style={{ textAlign: "left",margin:"5px",display:'inline'}}>
                   #{orderDetails.number}
                </h5>
                <div
                  style={{
                    display: "none",
                    justifyContent: "left",
                    alignItems: "left",
                  }}
                >
                  <span id="status_chip">preparing</span>
                </div>
                <h6
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin:"10px"
                  }}
                >
                  ORDER SUMMARY
                </h6>
                <div
                  style={{
                    textAlign: "left",
                    lineHeight: "8px",
                    width: "100%",
                    fontSize: "8px",
                  }}
                >
                  <b style={{display:'block',width:'100%',textAlign:'left',marginLeft:'10px'}}>{restaurant}</b>
                  <p>{addressPart.trim()}</p>
                  <p>{`GST: ${gstPart ? gstPart.trim() : ""}`}</p>
                  <p>{`FSSAI: ${fssaiPart ? fssaiPart.trim() : ""}`}</p>
                  <p style={{ display: "none" }}>TAX: {cgst}%</p>
                  <p style={{ display: "none" }}>NSSI: {nssi}</p>
                </div>

                <div
                  style={{
                    textAlign: "left",
                    lineHeight: "8px",
                    width: "100%",
                    fontSize: "8px"
                  }}
                >
                  <p>Invoice#: {invoiceNo}</p>
                  <p>
                    Date:  
                    {moment(orderDetails.createdAt).format("DD-MM-YYYY h:mm a")}
                  </p>
                </div>
                <hr style={{ border: "1px solid black",margin:'5px' }} />
                <table
                  style={{ fontSize: "15px", lineHeight: "15px",fontSize: "8px", }}
                  align="left"
                  height="auto"
                  width="100%"
                  
                >
                  <thead align="left">
                    <tr style={{borderBottom:'2px dotted #000',fontSize:'8px',fontWeight:'normal'}}>
                      <th align="start">Items</th>
                      <th>Qty</th>
                      <th>Amnt</th>
                    </tr>
          
                  </thead>
                  <tbody align="left">
                    {orderDetails
                      ? orderDetails.orderItems.map((item, index) => {
                          const subProArray =
                            item.sub_pro && JSON.parse(item.sub_pro);
                          return (
                            <tr key={index} style={{borderBottom:'1px dotted #000'}}>
                              <td align="start" style={{maxWidth:'130px'}}>{item.name}</td>
                              <td>{item.quantity}</td>
                              <td>
                                {renderCurrencySymbol()}{" "}
                                {item.price +
                                  (subProArray && subProArray.addons
                                    ? subProArray.addons.reduce(
                                        (acc, val) => acc + val.price,
                                        0
                                      )
                                    : 0)}
                              </td>
                              <td>
                                {item &&
                                item.status &&
                                item.status.toUpperCase() === "READY" ? (
                                  <input
                                    type="checkbox"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      accentColor: "#08eb0875",
                                    }}
                                    checked
                                    readOnly
                                  />
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                    <tr>
                      <td colSpan="4">
                        <hr style={{ border: "1px solid black" }}></hr>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="middle">
                        <b>Amount :</b>
                      </td>
                      <td align="start">
                        {renderCurrencySymbol()}{" "}
                        {orderDetails.totalPrice - orderDetails.taxPrice}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="middle">
                        <b>Tax ({cgst}%):</b>
                      </td>
                      <td align="start">
                        {renderCurrencySymbol()} {orderDetails.taxPrice}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="middle">
                        <b>Discount:</b>
                      </td>
                      <td align="start">
                        -
                        {orderDetails.discountAmount !== null
                          ? orderDetails.discountAmount
                          : 0}
                        {orderDetails.discountType === "price"
                          ? renderCurrencySymbol()
                          : "%"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h3
                  align="left"
                  style={{
                    margin: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    display:"table",
                    textAlign:"left"
                  }}
                  id="tot"
                >
                  Total: {renderCurrencySymbol()}{" "}
                  {orderDetails.discountType === "price"
                    ? orderDetails.totalPrice - orderDetails.discountAmount
                    :orderDetails.discountType === "percentage" ? orderDetails.totalPrice -
                      (orderDetails.totalPrice *
                        orderDetails.discountAmount) /
                        100:orderDetails.totalPrice}
                </h3>
              </div>
              <p style={{ textAlign: "left",fontSize:"8px" }}>
                Thank you, for your order! <br /> Hope to serve you again.
              </p>
              <p
                style={{
                  textAlign: "right",
                  marginTop: "10px",
                  float: "right",
                  fontSize:"10px"
                }}
              >
                <i>Powered by</i> <b>MenuLive</b>
              </p>
            </div>
          )}
        </div>

 

    </div>
  );
}

export default BillPrint;
