import React, { useEffect } from 'react'
import axios from 'axios';
import { useState } from 'react';
import configs from "../Constants";
import { Radio } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
let baseURL = configs.baseURL;

function Gallery({ onSelectImage,searchQuery  }) {
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(true); 
    useEffect(() => {
        axios.get('https://api.menulive.in/api/products/images')
            .then((response) => {
                console.log(response.data);
                setImages(response.data); 
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching images:', error);
                setLoading(false);
            });
    }, []);
    console.log(images)
    const handleImageSelect = (image) => {
        setSelectedImage(image);
        onSelectImage(image);
    };
    const filteredImages = images.filter((image) =>
    image.name.toLowerCase().includes(searchQuery.toLowerCase())
);


  return (
    <div>
    <h2>Gallery</h2>
    {loading ? (     
  <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open
>
  <CircularProgress color="inherit" />
</Backdrop> 

            ) :filteredImages.length === 0 ? (
                <div>No images found</div>
            ) : (
    <div style={{width:"100%",height:"100%",display:"flex",flexWrap:"wrap"}}>
    {filteredImages.map((image, index) => (
        <div key={index} style={{ position: 'relative', display: 'flex',flexDirection:"column",justifyContent:"center",alignItems:"center",width:"150px" }}>
            <Radio
                color="primary"
                checked={selectedImage === image}
                onChange={() => handleImageSelect(image)}
                style={{ position: 'absolute', top: 0, left: 0 }}
            />
            <img
                src={`${baseURL}/${image.image}`}
                alt={image.name}
                onError={(e) => {
                    e.target.src = './images/blank.jpg';
                }}
                width="150px"
                height="150px"
                style={{ padding: '10px' }}
            />
        </div>
    ))}
    </div>
                        )}
</div>
  )
}

export default Gallery